import React, { Fragment } from 'react';
// import About from './components/About';
import Section from '../../Section/Section';
import MeetingForm from '../../../components/Form/MeetingForm';
import { ImgDataMe } from '../../../data/dataUser';


// import ContactUs from './components/ContactUs';
// import Alliances from './components/Alliances';

const AboutComponent = () => {
  return (
    <Section>
    {/* <Fade delay={300} direction="right"> */}
    <div className="grid grid-cols-1 xl:grid-cols-3  gap-1">
      <div className="col-span-1 md:col-span-1 xl:col-span-2 flex flex-col justify-center mx-2 xl:ml-6">
        <h2 className="text-6xl xl:text-5xl text-center  text-primary font-bold">
            Sobre mí
          </h2>
        <div className='grid grid-cols-1 xl:grid-cols-2'>
        <div className="col-span-2 xl:col-span-1 flex flex-col ">
        {ImgDataMe?.length > 0 ? ImgDataMe.map((item)=>
                <div key={item.id}>
                  <img
                  src={item.img}
                  alt="about-img"
                  className="rounded-full  h-[200px] w-[200px] mt-10 mx-14 md:mx-2 xl:w-[400px] xl:h-[400px] xl:mt-36 xl:mx-2 object-cover"
                />
              </div>)
            : <img
            src="https://res.cloudinary.com/dvdb33uyj/image/upload/v1691681682/Projects/unne/img/experienciaUsuarios/mujer.png"
            alt="about-img"
            className="rounded-full  h-[200px] w-[200px] mt-10 mx-14 md:mx-2 xl:w-[370px] xl:h-[370px] xl:mt-20 xl:mx-2"
          /> }

        </div>
        <div className="col-span-2 xl:col-span-1">
          <p className="text-lg xl:text-xl text-gray-700 mt-8 ml-0 xl:ml-1 xl:text-left">
          Jorge Barra Sanhueza, ingeniero en gestión de empresas que en el año 2014 se constituye en corredor de propiedades y socio fundador de Integridad Propiedades. Posición desde la cual ha gestionado operaciones de compraventa de distintos tipos de inmuebles: casas, departamentos, estacionamientos, terrenos, sitios eriazos, parcelas, con acciones también en arriendo de inmuebles, disponiendo en cada gestión una elevada actitud de servicio, respeto, buena atención y comunicación  con sus clientes, logrando consolidar con ellos una relación de confianza que le ha permitido iniciar y concretar variadas operaciones gracias a la recomendación de éstos. Cuenta con el apoyo de otros gestores inmobiliarios, de abogados para estudio de títulos o redacción de escrituras, regularización de títulos, trámites notariales, ante el registro civil, juicios de partición, entre otros.
          Entre sus gestiones también encontramos la remodelación o restauración de inmuebles para lograr un mejor precio de venta.
            <br />
            <br />
            Esto lo constituye en un profesional integral y preparado en la gestión de compraventa de inmuebles, donde como sello personal busca desarrollar su labor con un elevado concepto de respeto, seriedad, confianza e integridad.
            <br />
          </p>

          <p className="text-lg xl:text-xl text-gray-700 mt-8 ml-0 xl:ml-1 xl:text-left">
           Jorge Barra.
          </p>
          <p className="text-xl xl:text-xl text-gray-700 ml-0 xl:ml-1  xl:text-left">
            Corredor de propiedades.
          </p>
        </div>
        </div>
      </div>
      <div className="col-span-1 md:col-span-1 xl:col-span-1 flex flex-col justify-center mx-2 xl:ml-24">
      <MeetingForm 
            title="Contacto corredor"
            subtitle="Enviar mensaje"
          />
      </div>

      {/* 
        <div className="pt-10">
          <h2 className="text-2xl xl:text-4xl font-bold text-black text-center sm:text-start">
            “Un largo camino
            <br />
            se inicia con un solo paso...”
          </h2>
        </div> */}
    </div>
    {/* </Fade>  */}
  </Section>
    // <Fragment>
    //   <About />
    // </Fragment>
  );
};

export default AboutComponent;
